import React from 'react'
import { BlockDisclaimerCentralBank } from '@blocks/BlockDisclaimerCentralBank'
import PageAnimation from '@shared/components/page-animation'
import { MainV2 } from '@shared/components/sections/main-v2'
import { ViewTabs } from '@shared/components/view-tabs'

import styles from './style.module.scss'
import Head from 'next/head'
import { DEFAULT_CITY } from 'regions/list'

export type MainPaveV2Props = {
	rootH1Text: string
	city: string
}

const jsonLD = (city?: string) => ({
	'@context': 'https://schema.org',
	'@type': 'Organization',
	name: 'Credit.Club',
	address: {
		'@type': 'PostalAddress',
		streetAddress: 'БЦ «Высоцкий», ул. Малышева 51, офис 25/07',
		addressLocality: 'Екатеринбург',
		addressRegion: 'Свердловская область',
		postalCode: '620000',
		addressCountry: {
			'@type': 'Country',
			name: 'RU',
		},
	},
	telephone: '+7 800 775 80 09',
	url: `https://credit.club/${city ?? DEFAULT_CITY}`,
	logo: 'https://credit.club/og/images/graph-loan.png',
	image: 'https://credit.club/og/images/graph-loan.png',
	sameAs: [
		'https://vk.com/credit.club',
		'https://www.youtube.com/channel/UCmSKC-7TCBJEQla4Mfo-Gzg/videos',
		'https://dzen.ru/procent_creditclub',
	],
	description:
		'Кредит под залог квартиры на любые цели. Без страховок и скрытых комиссий. Любая кредитная история. Быстрое решение.',
})

export const View = ({ city, rootH1Text }: MainPaveV2Props = {} as MainPaveV2Props) => {
	return (
		<PageAnimation>
			<Head>
				<script
					type='application/ld+json'
					dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD(city)) }}
				/>
			</Head>
			<div className={styles.sectionMain}>
				<MainV2 h1={rootH1Text} />
			</div>
			<BlockDisclaimerCentralBank />
			<ViewTabs />
		</PageAnimation>
	)
}
