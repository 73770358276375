import React from 'react'
import { Section } from '@shared/components/section'
import { Video } from '@shared/components/video'
import { ExternalPaths } from '@shared/menu-items/paths'
import cx from 'classnames'

import styles from './style.module.scss'

interface Props {
	sectionClassName?: string
	video?: {
		poster: string
		src: string
	}
}

const defaultVideo = {
	poster: '/images/webp/youtube-channel-poster.webp',
	src: '/videos/youtube-channel.mp4',
}

export const View = ({ sectionClassName, video = defaultVideo }: Props) => (
	<Section className={cx({ [styles.wrapper]: !sectionClassName }, sectionClassName)}>
		<div className={styles.summary}>
			<h2>
				Ведём канал <br /> на Youtube
			</h2>
			<h3 className={styles.subtitle}>
				Снимаем полезные ролики и рассказываем, как работают кредиты и займы.
			</h3>
			<div className={styles.seeAll}>
				<a
					target='_blank'
					rel='noopener noreferrer nofollow'
					className='main-link'
					href={ExternalPaths.companyYoutubeChannel}
				>
					Смотреть все ролики
				</a>
			</div>
		</div>
		<div className={styles.video}>
			<Video src={video.src} poster={video.poster} />
		</div>
	</Section>
)
