import React, { useEffect, useState } from 'react'
import scrollToElement from '@shared/pipes/scroll'

import { Controllers } from './components/controllers'
import { TabsContent } from './components/tabs-content'
import { TabsNames } from './model'
import Image from 'next/image'

export const View = () => {
	const [activeTab, setActiveTab] = useState<TabsNames>('loan')

	useEffect(() => {
		const handleClicked = () => {
			setActiveTab('loan')
			setTimeout(() => {
				scrollToElement(`[data-id="form-version-2"]`, {
					align: {
						top: 0,
						topOffset: 100,
					},
				})
			}, 300)
		}

		document.addEventListener('headerGetCreditClick', handleClicked)

		return () => {
			document.removeEventListener('headerGetCreditClick', handleClicked)
		}
	}, [])

	return (
		<>
			<Controllers setActiveTab={setActiveTab} activeTab={activeTab} />
			<TabsContent activeTab={activeTab} />
		</>
	)
}
